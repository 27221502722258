import React, { Component } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Helmet } from "react-helmet";
import "./style.scss";
import logo from "../img/benkyou-logo.png";
import phones from "../img/phones.png";
import appStore from "../img/app-store.png";
import playStore from "../img/google-play.png";
import hiraganaDemo from "../img/demo-hiragana.png";
import wanikaniDemo from "../img/demo-wanikani.png";
import criticalDemo from "../img/demo-critical.png";
import loader from "../img/loader.gif";
import { Link } from "gatsby";

export default class BenkyouPage extends Component {
  // constructor(props){
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoading: false,
      errorMsg: null,
      successMsg: null
    };
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { email } = this.state;
    // console.log("--handleSubmit--", email);
    this.setState({
      isLoading: true,
      errorMsg: null,
      successMsg: null
    });
    try {
      const response = await addToMailchimp(email);
      // console.log("--success--");
      // console.log(response);
      if (response.result === "error") {
        const alreadySubscribed =
          response.msg.indexOf("is already subscribed") !== -1;
        this.setState({
          isLoading: false,
          errorMsg: alreadySubscribed
            ? "You're already subscribed!"
            : response.msg
        });
      } else {
        this.setState({
          isLoading: false,
          errorMsg: null,
          successMsg: "Thank you for signing up!"
        });
      }
    } catch (e) {
      // console.log("--there was an error--");
      // console.log(e);
      this.setState({
        isLoading: false,
        errorMsg: e.errorMsg
      });
    }
  };

  handleChangeText = event => {
    const email = event.target.value;
    this.setState({
      email
    });
  };

  renderHeader = () => {
    const { isLoading, errorMsg, successMsg } = this.state;

    return (
      <div className="header">
        <div className="headerInner">
          <img className="phonesImg" src={phones} alt="app preview" />
          <div className="cta">
            <img className="logo" src={logo} alt="logo" />
            <div className="appDescription">
              Hiragana, Katakana, and WaniKani swipe flashcards
            </div>
            <div className="storeButtons">
              <OutboundLink
                href="https://play.google.com/store/apps/details?id=fm.raf.benkyou"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="storeImg" src={playStore} alt="play store" />
              </OutboundLink>
              <div className="iosButton">
                <OutboundLink
                  href="https://itunes.apple.com/us/app/benkyou/id1450472240"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="storeImg" src={appStore} alt="app store" />
                </OutboundLink>
              </div>
            </div>
            <form className="email" onSubmit={this.handleSubmit}>
              <input
                type="text"
                name="email"
                placeholder="sign up for the mailing list"
                onChange={this.handleChangeText}
                disabled={isLoading}
              />
              <button type="submit" value="Submit" disabled={isLoading}>
                {isLoading ? <img src={loader} alt="loader" /> : "sign up"}
              </button>
            </form>
            {errorMsg !== null && <p className="errorMsg">{errorMsg}</p>}
            {successMsg !== null && <p className="successMsg">{successMsg}</p>}
          </div>
        </div>
      </div>
    );
  };

  renderHiraganaSection() {
    return (
      <div className="hiraganaSection">
        <div className="gradient" />
        <div className="inner">
          <div className="text">
            <h1>Easy swipe flashcards for Hiragana and Katanana</h1>
            <p>
              If you're just starting out learning Japanese, you can use these
              easy swipe flashcards to memorize the basic kana. No need to make
              your own.
            </p>
          </div>
          <img src={hiraganaDemo} className="demoImage" alt="hiragana demo" />
        </div>
      </div>
    );
  }

  renderWaniKaniSection() {
    return (
      <div className="waniKaniSection">
        <div className="gradient" />
        <div className="inner">
          <img src={wanikaniDemo} className="demoImage" alt="WaniKani demo " />
          <div className="text">
            <h1>Study WaniKani on the go</h1>
            <p>
              If you're a serious student of Japanese and invested in a{" "}
              <OutboundLink
                href="https://www.wanikani.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WaniKani
              </OutboundLink>{" "}
              subscription, you can use your WaniKani API key to study lessons
              you haven't unlocked.
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderCriticalSection() {
    return (
      <div className="criticalSection">
        <div className="gradient" />
        <div className="inner">
          <div className="text">
            <h1>Study recently unlocked and critical items</h1>
            <p>
              You can improve your WaniKani reviews by studying your critical
              items: those that you get right less than 75% of the time.
            </p>
          </div>
          <img
            src={criticalDemo}
            className="demoImage"
            alt="critical items demo"
          />
        </div>
      </div>
    );
  }

  renderHelmet() {
    const pageTitle = "Benkyou App - Swipe Flashcards for Japanese";
    const description =
      "Learn Japanese on the go with Hiragana, Katakana, and WaniKani swipe flashcards";
    const imageUrl = "https://benkyou.cards/sharing-image.png";
    const imageWidth = 1200;
    const imageHeight = 630;
    const url = "https://benkyou.cards";

    return (
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <link rel="image_src" type="image/jpeg" href={imageUrl} />
        {/* Facebook */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />

        {/* twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
    );
  }

  render() {
    return (
      <div className="main">
        {this.renderHelmet()}
        <div className="mainShadow">
          {this.renderHeader()}
          {this.renderHiraganaSection()}
          {this.renderWaniKaniSection()}
          {this.renderCriticalSection()}
        </div>
        <div className="footer">
          <p>
            Built by{" "}
            <OutboundLink
              href="http://twitter.com/groundcontrol"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rafael Mendiola
            </OutboundLink>{" "}
            as Ramen Software LLC.&nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to="/privacy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    );
  }
}
